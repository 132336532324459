<div class="container content mt-4">
    <div class="row">
        <div class="col-sm-4">
            <img src="assets/img/bio/bio.jpg" class="img-fluid"/>
        </div>
        <div class="col-sm-8">

            <h2 class="font-weight-bold mb-4 title">
                About Diogo
            </h2>

            <p>
                I am a cinematographer and storyteller based in Portugal.
            </p>
            <p>
                Across this past few years I have had the pleasure to work and partner with some of the most creative
                and
                beautiful minds out there and I'm not stopping now.
            </p>
            <p>
                There is a certain magic about telling a storie about someone or some brand, and sometimes just words or
                photos
                aren't enough. My goal is to share different visions and perspectives around the world. Do you have the
                passion
                to do it with me?
            </p>

            <h2 class="font-weight-bold my-4 title">
                Contacts
            </h2>

            <li style="list-style-type:none" class="pb-1">
                <a href="mailto:diogobrasfc@gmail.com">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i> diogobrasfc@gmail.com
                </a>
            </li>
            <li style="list-style-type:none">
                <a href="https://www.instagram.com/brasdio/">
                    <i class="fa fa-instagram" aria-hidden="true"></i> instagram.com/brasdio
                </a>
            </li>

            <li style="list-style-type:none">
                <a href="https://www.youtube.com/channel/UC77W6WauCwMSqNXUPEJRyBw">
                    <i class="fa fa-youtube-play" aria-hidden="true"></i> youtube.com/brasdio
                </a>
            </li>
        </div>
    </div>
</div>
