<div class="container text-center">

  <h1 class="work-title">{{ workDetail.title }}</h1>

  <!-- Vídeos-->
  <div *ngFor="let video of workDetail.videos" class="ratio ratio-16x9 mb-3">
    <iframe [src]="video.url | safe" title="YouTube video" allowfullscreen></iframe>
  </div>

  <!-- Lista de frames -->
  <img *ngFor="let frame of workDetail.frames" [src]="frame" class="img-fluid workdetail-frame" alt="Responsive image">

  <br>
  <br>

  <!-- Descrição do vídeo da madeira -->
  <div *ngIf="this.workDetail.url == 'follow-your-passion-madeira-2022' " class="my-5 py-5">
    <p>
      Madeira is unlike anything else I've ever seen and it was a pleasure to go to this place with my friends Nelson
      and Catia.
    </p>

    <p>
      Producer and DP: Diogo Bras
      <br />
      Color: Diogo Bras
      <br />
      Drone Op: Diogo Bras and Nelson Sousa
      <br />
      Assistant filmmaking: Nelson Sousa
      <br />
      Photography: Catia Ferreira
      <br />
      Sound Design Assistant: Miguel Ângelo
      <br />
      Music by M83 - Outro
      <br /> <br />

      Thank you for watching and see you next time, Cheers

    </p>

  </div>

  <div *ngIf="this.workDetail.url == 'the-unexpected-journey'" class="my-5 py-5">

    <p>
      Of all the places I could travel to, Jordan has always been a wonder to me.
      <br />
      We are in fact driven by curiosity and I couldn't get off from that path.
      <br />
      Curiosity is a strong desire to learn, to seek out challenges and new experiences to broaden our horizons. It
      leads to knowledge and to the ability to make connections in every way.
      <br />
      Whether it's the food, the people, the lifestyle, the humbleness or the adventure I have always been curious about
      different cultures, people and places.
      <br /> <br />
      Film and photograph allow us to preserve a moment, to revisit that moment in a genuinely way.
      Suddenly all the joy, all the hustle, all the emotions appear us, in just a fraction of seconds that make us time
      travel.
      <br /> <br />
      Traveling was never about standing by the most beautiful places at the perfect time to make the most incredible
      shots. It is about creating unforgettable memories with the most passionate people and remembering it one day as
      we could go back in time.
      <br /> <br />
      If I had to choose one word to describe this trip and this country, it's unexpected.
      <br /> <br />
      شكرا
    </p>

  </div>


</div>
