

<div class="container-fluid">

    <a *ngFor="let work of works" [href]="'work/' + work.url">
        <img [src]="work.thumbImg" class="img-fluid homepage-thumb" alt="Responsive image">
    </a>

    <div class="container text-center clients">
        <h6 class="title">
            Clients and Partners
        </h6>

        <div class="row align-items-center my-5">
            <div *ngFor="let client of clients" class="col-sm align-self-center">
                <img [src]="'assets/img/clients/' + client + '.png' " class="img-fluid client-img" />
            </div>
        </div>

    </div>

</div>

