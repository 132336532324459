import { Component, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorksService } from 'src/app/services/works.service';
import {DomSanitizer} from '@angular/platform-browser';



@Component({
  selector: 'app-work-detail',
  templateUrl: './work-detail.component.html',
  styleUrls: ['./work-detail.component.css']
})
export class WorkDetailComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private worksService : WorksService, private sanitizer: DomSanitizer) { }

  workDetail : any = {};

  urlYoutube: any = ""

  ngOnInit(): void {

    var title = this.activatedRoute.snapshot.paramMap.get('title');
    this.workDetail = this.worksService.getWorkByTitle(title);

  }


}
